:root {
    --focus-color: ActiveBorder;
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    max-height: 100vh;
    font-family: 'Segoe UI Web (West European)';
}

@font-face {
    font-family: 'Segoe UI Web (West European)';
    src: url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semibold.woff2') format('woff2'), url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.sendMessage {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    bottom: 0;
    position: sticky;
}

.sendMessageInput {
    width: 100%;
    min-height: 40px;
}

.sendIcons {
    display: flex;
    flex-direction: row;
    margin-left: 1%;
    gap: 10px;
}

.sendMessageIconRegular {
    background-image: url("../icons/send.svg");
}

.sendMessageIconFC {
    background-image: url("../icons/send_forced_colors.svg");
}

.sendMessageIcon {
    background-size: cover;
    background-color: #FFFFFF; /*prevents the shadow*/
    cursor: pointer;
    display: flex;
    width: 35px;
    height: 35px;
    border-style: none;
    transition: all 0.2s; /* add transition for smoother effect */
}

    .sendMessageIcon:active, .sendMessageIcon:hover {
        transform: scale(0.9); /* scale down when clicked */
        opacity: 0.5; /* reduce opacity when clicked */
        background-color: #FFFFFF;
    }

.downloadTranscriptLink {
    font-size: 10px;
}

.micButtonOffFC {
    background-image: url("../icons/mic_off_forced_colors.svg");
}

.micButtonOffRegular {
    background-image: url("../icons/mic_off.svg");
}

.micButtonOff {
    background-color: #FFFFFF;
    background-size: cover;
    cursor: default;
    display: flex;
    width: 35px;
    height: 35px;
    border-style: none;
    outline: none;
    cursor: pointer;
    transition: all 0.2s;
}

.micButtonOnFC {
    background-image: url("../icons/mic_on_forced_colors.svg");
}

.micButtonOnRegular {
    background-image: url("../icons/mic_on.svg");
}

.micButtonOn {
    background-color: #FFFFFF;
    background-size: cover;
    cursor: default;
    display: flex;
    width: 35px;
    height: 35px;
    border-style: none;
    outline: none;
    cursor: pointer;
    transition: all 0.2s;
}

    .micButtonOff:hover, .micButtonOn:hover {
        background-color: #FFFFFF;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    }

.micButtonOff:active, .micButtonOn:active {
    transform: scale(0.9); /* scale down when clicked */
    opacity: 0.5; /* reduce opacity when clicked */
    box-shadow: none;
}

@media (max-width: 768px) {
    .micButtonOff, .micButtonOn {
       transition: none;
    }
}

.chatWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.my-2 {
    margin-top: 0 !important;
    width: 100%;
    height: calc(100vh - 140px); /* subtract height for header + text message input */
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
}

    .chatBox > label {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: #f5f5f5;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: normal;
        color: #555;
        box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
    }


@media screen and (max-device-width: 480px) and (orientation: portrait) {
    .my-2 {
        height: calc(100vh - 270px);
    }
}
@media screen and (max-device-width: 480px) and (orientation: landscape) {
    .my-2 {
        height: calc(100vh - 220px);
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .my-2 {
        height: calc(100vh - 200px);
    }
}

.messageList {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 40px; /*space between the label and the first message */
}

.messageList li {
        margin-bottom: 10px;
}

@media (max-width: 768px) {
    .messageList {
        margin-top: 70px;
    }
} 
.messageContainer {
    display: flex;
    align-items: flex-start;
    padding: 10px 15px;
    max-width: 80%;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
}

.messageContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 15px;
    max-width: 80%;
    border-radius: 20px;
    font-size: 16px;
    line-height: 1.5;
    background-color: #f2f2f2;
    color: #333;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.messageList .messageContent .messageText {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
}

.participantList {
    list-style-type: none
}

.participantIconContainer {
    display: inline;
    position: relative;
    height: 30px;
    width: 30px;
}

.username {
    font-size: 12px;
    color: #6e6e6e;
    margin-bottom: 5px; /* spacing between the username and the message text */
}

.sourceTextSubtext {
    font-size: 14px;
    color: #6e6e6e;
    margin-top: 5px;
    padding-left: 10px; /* space before vertical bar */
    border-left: 1px solid #6e6e6e; /* Add a vertical bar */
}

.userIconContainer {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.userIcon {
    width: 30px;
    height: 30px;
    background-color: #f2f2f2; /* Set a default background color */
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    border-radius: 50%;
    margin-right: 5px;
}

.participantCountIcon {
    background: white;
    position: absolute;
    bottom: -5px;
    right: 0px;
    width: 10px;
    height: 10px;
    color: black;
    border-radius: 50%;
    font-size: 8px;
    font-weight: bold;
    display: flex; /* or inline-flex */
    align-items: center;
    justify-content: center;
}

.messageText {
    margin: 0;
    padding: 0;
}

/*Nav bar*/

.navbar {
    z-index: 11;
}

.green-bg {
    background-color: #107C10 !important;
}

.customBrand {
    color: #FFFFFF;
    font-weight: 500;
    display: flex;
    padding-left: 40px;
    pointer-events: none;
}

@media (max-width: 768px) {
    .customBrand {
        padding-left: 10px; /* Reduce padding for smaller screens */
    }

    .navbar-item {
        margin-left: 2px; /* Adjust as needed */
    }
}

.navbar-nav {
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    display: inline;
    justify-content: center;
    float: right;
}

.navbar-item {
    color: #FFFFFF;
    display: inline-flex;
    align-items: center;
    margin-left: 1rem;
    font-weight: bold;
}

@media (max-width: 768px) {
    .navbar-item {
        margin-left: 0.5rem;
    }
}

.navbarButton {
    background-color: transparent;
    border-color: transparent;
    padding: 0; /* Remove padding around the button to reduce space */
}

/* Drawer */
.drawer {
    width: 70%
}

.drawerHeader {
    text-align: center;
    margin-bottom: 15px;
    font-size: 18px;
}

.participantAvatarBubble {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

    .participantAvatarBubble .userIcon {
        margin-right: 8px;
    }

/*Sidebar*/

/* TODO:AJN: sidebar needs to flex vertically if there are a lot of participants*/
/* TODO:AJN: sidebar participant name font size should adjust to match available width */
.sidebar {
    position: fixed;
    top: 65px;
    right: -300px;
    width: 300px;
    height: calc(100vh - 65px);
    border-left: 1px solid #ccc;
    overflow-y: auto;
    z-index: 10;
    padding: 10px;
    transition: right 0.3s;
    background-color: white;
}

@media (max-width: 576px) {
    .sidebar {
        top: 108px;
        height: calc(100vh - 108px);
    }
}

.sidebar-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    font-size: 20px;
    border-bottom: 1px solid #ddd;
    text-align: center;
}

.exitSidebarButtonFC {
    background-image: url("../icons/dismiss_forced_colors.svg");
}

.exitSidebarButtonRegular {
    background-image: url("../icons/dismiss.svg");
}

.exitSidebarButton {
    background-color: #FFFFFF;
    background-size: cover;
    cursor: default;
    display: flex;
    width: 30px;
    height: 30px;
    border-style: none;
    opacity: 1;
    position: absolute;
    top: 5px;
    right: 5px;
}

    .micButtonOff:focus,
    .micButtonOn:focus,
    .navbarButton:focus,
    .downloadTranscriptLink:focus,
    .modalHeader:focus,
    .modalBody:focus,
    .modalButton:focus,
    .settingsToggle:focus,
    .voiceSelectionInput:focus,
    .exitSidebarButton:focus,
    .sendMessageTextBox:focus,
    .sendMessageIcon:focus {
        box-shadow: 0px 0px 0px 4px var(--focus-color);
        /* Visible in Windows high-contrast themes */
        outline-color: transparent;
        outline-width: 4px;
        outline-style: dotted;
    }

    .sendMessageIcon:focus,
    .micButtonOn:focus,
    .micButtonOff:focus
    {
        background-color: #FFFFFF;
    }

.sidebar-table {
    position: relative;
    top: 20px;
    left: 10px;
    width: 100%;
    padding-bottom: 20px;
}

.participant-row {
    display: flex;
    width: 100%;
    align-items: start;
}

.participant-name {
    display: flex;
    margin-left: 5px;
}

.sidebar-userIcon {
    margin-right: 2px;
    margin-bottom: 5px;
}

.sidebar-open {
    right: 0;
}

.sidebar-overlay-open {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: openSidebarAnimation 0.3s;
    background-color: rgba(60, 60, 60, 0.5);
    z-index: 9;
}

.sidebar-overlay-closed {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: closeSidebarAnimation 0.3s;
    background-color: transparent;
    z-index: 9;
}

@keyframes openSidebarAnimation {
    from {
        background-color: transparent;
    }

    to {
        background-color: rgba(60, 60, 60, 0.5);
    }
}

@keyframes closeSidebarAnimation {
    from {
        background-color: rgba(60, 60, 60, 0.5);
    }

    to {
        background-color: transparent;
    }
}

@media (forced-colors: active) {
    .form-check-input {
        background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27ButtonText%27/></svg>") !important;
        background-color: grey;
    }

        .form-check-input:checked {
            background-color: Highlight !important;
            border-color: #6668 !important;
            background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27ButtonText%27/></svg>") !important;
        }
}

@media (forced-colors: none) {
    .form-check-input {
        background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23fff%27/></svg>") !important;
        background-color: #949494;
    }

    .form-check-input:checked {
        background-color: #107C10 !important;
        border-color: #6668 !important;
        background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23fff%27/></svg>") !important;
    }
}

.form-check-input:focus {
    border-color: #6668 !important;
    outline: 0;
    /* Visible in Windows high-contrast themes */
    outline-color: transparent;
    outline-width: 4px;
    outline-style: dotted;
}


.setting-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    padding: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.setting-name {
    margin-bottom: 0.5em;
}

.voice-selector {
    width: 100%;
}

    .voice-selector select {
        border: 1px solid #ccc;
        padding: 5px;
        font-size: 14px;
        background-color: #fff;
        color: #333;
        cursor: pointer;
    }

.overlay-open {
    opacity: 1;
    pointer-events: auto;
}
.scrollable-settings {
    overflow-y: hidden;
    overflow-x: hidden;
    max-height: 100vh;
}

input {
    height: 40px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    white-space: pre-line;
    position: relative;
    top: -7px;
}

::-moz-placeholder { /* Firefox 19+ */
    white-space: pre-line;
    position: relative;
    top: -7px;
}

:-ms-input-placeholder { /* IE 10+ */
    white-space: pre-line;
    position: relative;
    top: -7px;
}

:-moz-placeholder { /* Firefox 18- */
    white-space: pre-line;
    position: relative;
    top: -7px;
}

