@font-face {
    font-family: 'Segoe UI Web (West European)';
    src: url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-regular.woff2') format('woff2'), url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
body, html {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}
.content-wrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.content-container {
    margin-top: 30px;
}

.CardFooter {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
}

.cardLabel {
    padding-top: 10px; 
}

.version {
    align-self: center;
    padding-left: 5px;
}

.learnMoreItem:active {
    background-color: darkgray;
}

.link {
    font-family: 'Segoe UI Web (West European)';
    font-size: 14px;
    color: #4A4A4A;
    margin: 0 5px;
}

    .link:focus {
        outline: none;
    }
    .link:active {
        color: green;
    }
    .link:hover {
        color: #107C10;
    }

.dropdown-toggle {
    background-color: #107C10;
}
    .dropdown-toggle::after {
        content: none;
    }

.dropdown-menu {
    min-height:100%;
    overflow-y: auto;
    min-width: 100%;
    padding: 5px;
}
.dropdown-item.disabled {
    color: black;
    opacity: 1;
}

.footer-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}
.title {
    color: #4A4A4A;
    font-weight: 600;
}
.infoTitle {
    text-align: left;
    color: #4A4A4A;
    font-weight: 700;
    font-size: 22px;
}
/*Break the barrier heading image and text wrapper*/
.cardContentWrapper {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}
.cardContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.howToHeader {
    text-align: left;
    color: #4A4A4A;
    font-weight: 700;
    font-size: 22px;
}
.howToTitle {
    text-align: left;
    color: #4A4A4A;
    font-weight: 700;
    font-size: 22px;
}
.howTosub {
    font-family: 'Segoe UI Web (West European)';
    font-size: 16px;
    color: #4A4A4A;
    text-align: left;
    display: flex;
}
.infoSubheading {
    font-family: 'Segoe UI Web (West European)';
    font-size: 14px;
    color: #4A4A4A;
    text-align: left;
    display: flex;
}
.howtoImages {
    width: 50px;
    object-fit: cover;
    margin-bottom: 20px;
    max-width: 100%;
    height: auto;
}
.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    border: 5px solid #107C10;
    border-top: 8px solid #4A4A4A;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.share-icons img {
    width: 25px;
    height: 25px;
}

.logo {
    width: auto;
    height: 60px;
    margin-right: 8px;
    margin-top: 8px;
}

.responsiveRow {
    flex-direction: column;
    display: flex;
}

@media (min-width: 480px) {
    .responsiveRow {
        flex-direction: row;
        display: flex;
    }

    .howToTitle .col-md-4 {
        margin-bottom: 20px;
    }
}

.infoForm {
    width: 100%;
    height: auto;
    margin: 0 auto;
    text-align: left;
    justify-content: center;
    align-content: center;
    display: flex;
    max-width: 500px;
    box-shadow: 10px 10px 10px 10px #D3D3D3;
    border: none;
    margin-left: 20px;
}

.mainForm {
    font-family: 'Segoe UI Web (West European)';
    border-top: solid #107C10 12px;
    border-color: #107C10;
    width: 100%;
    max-width: 450px;
    box-shadow: 2px 2px 2px 2px #D3D3D3;
    height: auto;
    position: relative;
    margin: 0 auto;
    box-shadow: 10px 10px 5px 5px #D3D3D3;
    text-align: left;
    justify-content: center;
    align-content: center;
    display: flex;
}

.roomcodeInput {
    text-transform: uppercase;
}

/* Responsive columns for main and info form */
@media (max-width: 970px) {
    .mainForm {
        max-width: 100%;
    }

    .infoForm {
        max-width: 100%;
        margin-top: 20px;
        margin-left: 0px;
    }
}

.card-body-padding {
    padding-right: 2em;
}

UhfFooter {
    width: 100%;
    margin-top: auto;
}
@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.slideDown {
    position: relative; 
    top: 0;
    left: 0;
    width: 100%;
    animation: slideDown 0.8s ease-out forwards;
    z-index: 1000;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.submit {
    background-color: #107C10;
    width: 100%;
    font-weight: 600;
}

    .roomcodeInput:focus,
    .roomcodeInputEmpty:focus,
    .nameInput:focus,
    .languageListInput:focus,
    .selectRegionInput:focus,
    .submit:focus,
    .learnMore:focus {
        box-shadow: 0px 0px 0px 4px var(--focus-color);
        /* Visible in Windows high-contrast themes */
        outline-color: transparent;
        outline-width: 4px;
        outline-style: dotted;
    }

.speechRegionFooter {
    font-size: 10px;
    color: #737373;
    padding: 6px;
}

Label {
    font-family: 'Segoe UI Web (West European)';
    font-size: 14px;
    color: #4A4A4A;
    text-align: left;
    display: flex;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
      background-color: #FFFFFF;
      color: #000000;
}