.content {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.microsoft-information {
    font-size: 18px;
    margin-bottom: 18px;
}

.contract-body {
    margin-top: 40px;
}

#contract-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

#contract-bullets {
    margin-left: 20px;
    margin-bottom: 20px;
}

.section-header {
    font-size: 20px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 20px;
}

.paragraph {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.summary-reference {
    margin-top: 40px;
    text-align: right;
}

.reference {
    font-size: 14px;
    font-style: italic;
}
